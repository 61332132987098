<template>
  <div id="default-layout">
    <DefaultNavigation />
    <main>
      <slot></slot>
    </main>
    <DefaultFooter />
  </div>
</template>

<style lang="postcss" scoped>
#default-layout {
  display: grid;
  min-height: 100dvh;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: min-content 1fr min-content;
}
</style>
